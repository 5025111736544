/*
 * @Author: gofive
 * @Date: 2020-09-18 19:17:59
 * @LastEditTime: 2020-09-18 19:18:07
 * @Description: Todo
 */
import React, { Component } from "react";

export default class NotFound extends Component {
  render() {
    return <h3>Sorry!Page Not Found!</h3>;
  }
}
