// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-js": () => import("./../../../src/pages/course.js" /* webpackChunkName: "component---src-pages-course-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-school-js": () => import("./../../../src/pages/school.js" /* webpackChunkName: "component---src-pages-school-js" */),
  "component---src-pages-toy-js": () => import("./../../../src/pages/toy.js" /* webpackChunkName: "component---src-pages-toy-js" */)
}

